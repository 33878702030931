<template>
  <div class="rounded-lg bg-white p-4 sm:p-6 md:p-8">
    <PenjualanHeader :is-draft="isDraft" />
    <div class="mt-5">
      <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-6">
          <base-input
            v-model="order.attributes.date"
            disabled
            type="date"
            name="tanggal"
            id="tanggal"
            label="Tanggal"
            inset
          />
        </div>

        <div class="sm:col-span-3">
          <div class="relative">
            <button
              v-if="!isDraft"
              type="button"
              @click="handleOpenDestinationOfficeModal"
              class="absolute inset-y-0 top-4 right-4 z-10 flex cursor-pointer items-center"
            >
              <Icon
                icon="heroicons:magnifying-glass-circle-20-solid"
                class="h-5 w-5 text-gray-500 hover:text-gray-700"
              />
            </button>
            <base-input
              v-model="order.relationships.destinationOffice.attributes.code"
              :disabled="isDraft"
              ref="focusNamaStockist"
              @blur="handleSearchDestinationOffice"
              type="text"
              name="code"
              id="code"
              :label="`Kode ${destinationOfficeTypeNormalize}`"
              :placeholder="`Masukkan Kode ${destinationOfficeTypeNormalize}`"
              inset
            />
          </div>
        </div>
        <div class="sm:col-span-3">
          <base-input
            :value="order.relationships.destinationOffice.attributes.name"
            readonly
            type="text"
            name="name"
            id="name"
            :label="`Nama ${destinationOfficeTypeNormalize}`"
            :placeholder="`Nama ${destinationOfficeTypeNormalize}`"
            disabled
            inset
          />
        </div>

        <div v-if="destinationOfficeType != 'supplier'" class="sm:col-span-3">
          <div class="relative">
            <template v-if="selectableDestinationWarehouse && !isDraft">
              <button
                type="button"
                @click="handleOpenOriginWarehouseModal"
                class="absolute inset-y-0 top-4 right-4 z-10 flex cursor-pointer items-center"
              >
                <Icon
                  icon="heroicons:magnifying-glass-circle-20-solid"
                  class="h-5 w-5 text-gray-500 hover:text-gray-700"
                />
              </button>
              <base-input
                v-model="order.relationships.originWarehouse.attributes.code"
                :disabled="isDraft"
                :placeholder="`Masukkan Kode Gudang`"
                ref="focusNamaStockist"
                @blur="handleSearchOriginWarehouse"
                type="text"
                name="warehouse_code"
                id="warehouse_code"
                label="Kode Gudang"
                inset
              />
            </template>
            <base-input
              v-else
              v-model="order.relationships.originWarehouse.attributes.code"
              type="text"
              name="warehouse_code"
              id="warehouse_code"
              label="Kode Gudang"
              inset
              disabled
            />
          </div>
        </div>
        <div v-if="destinationOfficeType != 'supplier'" class="sm:col-span-3">
          <base-input
            :value="area"
            type="text"
            name="wilayah_harga"
            id="wilayah_harga"
            label="Wilayah Harga"
            placeholder="Masukkan Wilayah Harga"
            disabled
            inset
          />
        </div>

        <div class="sm:col-span-6">
          <label for="name" class="text-xs font-bold text-gray-700">
            Metode Pengiriman
          </label>
          <div class="mt-3 flex items-center space-x-8">
            <div
              v-if="
                forceShipment
                  ? order.attributes.order_shipment === 'pickup'
                  : true
              "
              class="items-center text-sm text-gray-700"
            >
              <input
                v-model="order.attributes.order_shipment"
                @change="handleChangeOrderShipment"
                :disabled="isDraft"
                :class="isDraft ? 'cursor-not-allowed' : null"
                type="radio"
                name="order_shipment"
                value="pickup"
                class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                aria-labelledby="privacy-setting-1-label"
                aria-describedby="privacy-setting-1-description"
              />
              Diambil
            </div>
            <div
              v-if="
                forceShipment
                  ? order.attributes.order_shipment === 'delivery'
                  : true
              "
              class="items-center text-sm text-gray-700"
            >
              <input
                v-model="order.attributes.order_shipment"
                @change="handleChangeOrderShipment"
                type="radio"
                name="order_shipment"
                :disabled="isDraft"
                :class="isDraft ? 'cursor-not-allowed' : null"
                value="delivery"
                class="mr-1 h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
                aria-labelledby="privacy-setting-1-label"
                aria-describedby="privacy-setting-1-description"
              />
              Dikirim
            </div>
          </div>
          <div
            v-if="
              order.attributes.order_shipment === 'delivery' &&
              order.relationships.destinationOffice.attributes.name
            "
            class="mt-6"
          >
            <h3 class="text-sm font-semibold">
              Alamat {{ destinationOfficeTypeNormalize }}
            </h3>
            <div v-if="order.isCustomAddress" class="divide-y-2">
              <p class="mt-2 mb-3 text-gray-600">
                {{ order.relationships.destinationAddress.attributes.address }},
                {{
                  order.relationships.destinationAddress.attributes.village
                    .name
                }},
                {{
                  order.relationships.destinationAddress.attributes.district
                    .name
                }},
                {{
                  order.relationships.destinationAddress.attributes.city.name
                }},
                {{
                  order.relationships.destinationAddress.attributes.province
                    .name
                }}
              </p>
              <div v-if="!isDraft" class="py-3">
                <button
                  type="button"
                  @click="handleOpenAddressModal"
                  class="bg-white-600 rounded-md border border-gray-300 py-2 px-4 text-xs text-gray-600 shadow-sm hover:bg-white hover:shadow-lg"
                >
                  Ganti Alamat
                </button>
                <button
                  type="button"
                  @click="hanldeResetAddress"
                  class="bg-white-600 ml-2 rounded-md border border-gray-300 py-2 px-4 text-xs text-gray-600 shadow-sm hover:bg-white hover:shadow-lg"
                >
                  Gunakan Alamat Lama
                </button>
              </div>
            </div>
            <div v-else class="divide-y-2">
              <p class="mt-2 mb-3 text-gray-600">
                {{ order.relationships.destinationOffice.attributes.address }}
              </p>
              <div v-if="!isDraft" class="py-3">
                <button
                  type="button"
                  @click="handleOpenAddressModal"
                  class="bg-white-600 rounded-md border border-gray-300 py-2 px-4 text-xs text-gray-600 shadow-sm hover:bg-white hover:shadow-lg"
                >
                  Pilih Alamat Lain
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DestinationOfficeModal
      :office-type="destinationOfficeType"
      :visible="visibleDestinationOfficeModal"
      @close="visibleDestinationOfficeModal = false"
      v-on:change="handleChangeOffice"
    />
    <OriginWarehouseModal
      :warehouse-type="originWarehouseType"
      :visible="visibleOriginWarehouseModal"
      @close="visibleOriginWarehouseModal = false"
      v-on:change="handleChangeOriginWarehouse"
    />
    <AddressModal
      :visible="visibleAddressModal"
      v-model="order"
      v-on:close="visibleAddressModal = false"
      v-on:change="handleChangeAddress"
    />
    <loading v-if="loading.visible" :text="loading.text" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PenjualanHeader from '@/components/penjualan/create/penjualan-header.vue';
import DestinationOfficeModal from './DestinationOfficeModal.vue';
import OriginWarehouseModal from './OriginWarehouseModal.vue';
import AddressModal from './AddressModal.vue';
import { loadingMixin } from '@/mixins/loading/loading.mixin';

export default {
  components: {
    PenjualanHeader,
    DestinationOfficeModal,
    AddressModal,
    OriginWarehouseModal,
  },
  mixins: [loadingMixin],
  props: {
    value: {
      type: Object,
      required: true,
    },
    area: {
      type: null,
      default: null,
    },
    destinationOfficeType: {
      type: String,
      default: 'stockist',
    },
    originWarehouseType: {
      type: String,
      default: 'pusat',
    },
    forceShipment: {
      type: Boolean,
      default: false,
    },
    selectableDestinationWarehouse: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'input',
    'change-address',
    'change-order-shipment',
    'change-destination',
    'change-origin-warehouse',
  ],
  data() {
    return {
      visibleDestinationOfficeModal: false,
      visibleOriginWarehouseModal: false,
      visibleAddressModal: false,
      mapOfficeCategoryId: {
        pusat: 1,
        kantor_pelayanan: 2,
        stockist_center: 3,
        stockist: 4,
        member: 5,
        supplier: 6,
      },
    };
  },
  computed: {
    ...mapGetters({
      getOffices: 'offices/getOffices',
    }),
    isDraft() {
      return !!this.order.id && !this.order.attributes.is_valid_for_payment;
    },
    order: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    rawDestinationOfficeCode() {
      return this.order.relationships.destinationOffice.attributes.code?.split(
        ' '
      )[0];
    },
    destinationOfficeTypeNormalize() {
      return this.destinationOfficeType
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
  },
  methods: {
    ...mapActions({
      fetchOffices: 'offices/fetchOffices',
      fetchWarehouses: 'warehouses/fetchWarehouses',
      createAlert: 'alert/createAlert',
    }),
    handleOpenDestinationOfficeModal() {
      this.visibleDestinationOfficeModal = true;
    },
    handleOpenOriginWarehouseModal() {
      this.visibleOriginWarehouseModal = true;
    },
    async handleSearchDestinationOffice() {
      this.startLoading('Mencari Kantor');

      const offices = await this.fetchOffices({
        'filter[office_type]': this.officeType,
        'fields[simple-offices]': 'name,code,office,office_id',
        'filter[office_category_id]':
          this.mapOfficeCategoryId[this.destinationOfficeType],
        'filter[code]': this.rawDestinationOfficeCode,
        include: 'office,office.area,office.current-warehouse',
      });

      if (offices && offices.data.data.length) {
        if (offices.data.data.length) {
          this.setDestinationOffice(offices.data.data[0]);
        }
      }

      this.stopLoading();
    },
    async handleSearchOriginWarehouse() {
      this.startLoading('Mencari Gudang');

      const warehouses = await this.fetchWarehouses({
        warehouseType: this.originWarehouseType,
        search: this.order.relationships.originWarehouse.attributes.code,
      });

      if (warehouses && warehouses.data.data.length) {
        if (warehouses.data.data.length) {
          this.setOriginWarehouse(warehouses.data.data[0]);
        }
      }

      this.stopLoading();
    },
    handleChangeOrderShipment() {
      this.$emit('change-order-shipment');
    },
    handleChangeOffice(office) {
      this.setDestinationOffice(office);

      this.visibleDestinationOfficeModal = false;
    },
    async handleChangeOriginWarehouse(warehouse) {
      this.setOriginWarehouse(warehouse);

      this.visibleOriginWarehouseModal = false;
    },
    handleOpenAddressModal() {
      this.visibleAddressModal = true;
    },
    handleChangeAddress() {
      this.$emit('change-address');
    },
    hanldeResetAddress() {
      this.order.isCustomAddress = false;
      this.order.relationships.destinationAddress.attributes.province = {
        id: null,
        name: null,
      };
      this.order.relationships.destinationAddress.attributes.city = {
        id: null,
        name: null,
        area: null,
      };
      this.order.relationships.destinationAddress.attributes.district = {
        id: null,
        name: null,
      };
      this.order.relationships.destinationAddress.attributes.village = {
        id: null,
        name: null,
      };

      this.handleChangeAddress();
    },
    setDestinationOffice(office) {
      let area;
      let warehouse;
      const related = this.getSingleIncluded(
        this.getOffices,
        office.relationships.office.data.id
      );
      try {
        area = this.getSingleIncluded(
          this.getOffices,
          related.relationships.area.data.id
        );
        this.order.relationships.destinationOffice.attributes.code = `${office.attributes.code} (${area.attributes.code})`;
        this.order.relationships.destinationOffice.relationships.area.id =
          area.id;
        this.order.relationships.destinationOffice.relationships.area.attributes.code =
          area.attributes.code;
      } catch (error) {
        this.order.relationships.destinationOffice.attributes.code = `${office.attributes.code}`;
        if (this.destinationOfficeType != 'supplier') {
          this.createAlert({ data: 'Destinasi kantor tidak memiliki area' });
        }
      }

      try {
        warehouse = this.getSingleIncluded(
          this.getOffices,
          related.relationships['current-warehouse'].data.id
        );
        this.order.relationships.destinationWarehouse.id = warehouse.id;
        this.order.relationships.destinationWarehouse.attributes.code =
          warehouse.attributes.code;
      } catch (error) {
        if (this.destinationOfficeType != 'supplier') {
          this.createAlert({
            data: 'Destinasi kantor tidak memiliki warehouse',
          });
        }
      }

      this.order.relationships.destinationOffice.id = office.attributes.office_id;
      this.order.relationships.destinationOffice.attributes.office_id =
        office.attributes.office_id;
      this.order.relationships.destinationOffice.attributes.name =
        office.attributes.name;
      this.order.relationships.destinationOffice.attributes.balance =
        office.attributes.balance;
      this.order.relationships.destinationOffice.attributes.restock_balance =
        office.attributes.restock_balance;
      this.order.relationships.destinationOffice.attributes.deposit_balance =
        related.attributes.deposit_balance;
      this.order.relationships.destinationOffice.attributes.multiplied_deposit_balance =
        related.attributes.multiplied_deposit_balance;
      this.order.relationships.destinationOffice.attributes.address =
        office.attributes.address;
      this.order.relationships.destinationOffice.attributes.city =
        office.attributes.city;
      this.order.relationships.destinationOffice.attributes.phone =
        office.attributes.phone;

      this.$emit('change-destination');
    },
    setOriginWarehouse(warehouse) {
      this.order.relationships.originWarehouse.id = warehouse.id;
      this.order.relationships.originWarehouse.attributes.code =
        warehouse.attributes.code;
      this.order.relationships.originWarehouse.attributes.name =
        warehouse.attributes.name;
      this.order.relationships.originWarehouse.relationships.area.attributes.code =
        warehouse.attributes.area_code;

      this.$emit('change-origin-warehouse');
    },
  },
};
</script>
