<template>
  <div>
    <BaseModal
      :showing="visible"
      :showClose="visible"
      :backgroundClose="true"
      size="max-w-6xl"
      v-on:close="$emit('close')"
    >
      <div class="flex justify-between px-4 py-5 sm:px-6">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Daftar {{ officeTypeNormalize }}
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Data {{ officeTypeNormalize }} berdasarkan gudang yang dipilih
          </p>
        </div>
        <div>
          <img src="@/assets/images/logo.png" alt="" class="w-52" />
        </div>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div class="flex justify-end">
          <base-search
            placeholder="Cari Kode"
            v-model="filter.search"
            v-on:input="handleSearch"
          />
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getOffices.meta.page.total"
              :perPage="getOffices.meta.page.perPage"
              :currentPage="getOffices.meta.page.currentPage"
              :meta="getOffices.meta"
              @pagechanged="handleChangePage"
              cursor
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode {{ officeType }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama {{ officeType }}
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Alamat
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      No. Telp
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody>
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getOffices.data"
                    :key="data.id"
                    :class="[
                      'cursor-pointer',
                      dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                    ]"
                    @click="handleChangeOffice(data)"
                  >
                    <template v-if="data.relationships">
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                      >
                        {{ data.attributes.code }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ data.attributes.name }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{
                          getSingleIncluded(
                            getOffices,
                            data.relationships.office.data.id
                          ).attributes.address
                        }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{
                          getSingleIncluded(
                            getOffices,
                            data.relationships.office.data.id
                          ).attributes.phone
                        }}
                      </td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <base-button @click="$emit('close')" type="button"> Tutup </base-button>
      </div>
    </BaseModal>
    <loading v-if="isLoading" text="Memuat Kantor" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/components/base/BaseModal';
import Datatable from '@/components/base/Datatable';
import { debounce } from 'debounce';
import BaseSearch from '@/components/base/Search.vue';
import BaseButton from '@/components/base/BaseButton.vue';

export default {
  components: { BaseModal, Datatable, BaseSearch, BaseButton },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    params: Object,
    officeType: {
      type: String,
      required: true,
    },
  },
  emits: ['change'],
  data() {
    return {
      isLoading: false,
      filter: {
        search: null,
      },
      page: {
        number: 1,
      },
      mapOfficeCategoryId: {
        pusat: 1,
        kantor_pelayanan: 2,
        stockist_center: 3,
        stockist: 4,
        member: 5,
        supplier: 6,
      },
    };
  },
  computed: {
    ...mapGetters({
      getOffices: 'offices/getOffices',
    }),
    officeTypeNormalize() {
      return this.officeType
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.loadOffices({ resetPage: true, resetFilter: true });
      }
    },
  },
  methods: {
    ...mapActions({
      fetchOffices: 'offices/fetchOffices',
    }),
    handleSearch: debounce(function () {
      this.loadOffices({ resetPage: true });
    }, 300),
    handleChangePage(page) {
      this.loadOffices(page);
    },
    handleChangeOffice(office) {
      this.$emit('change', office);
    },
    async loadOffices({ resetPage, resetFilter, ...args } = {}) {
      this.isLoading = true;

      if (resetPage) {
        this.page.number = 1;
      }

      if (resetFilter) {
        this.filter.search = null;
      }

      await this.fetchOffices({
        ...args,
        ...this.params,
        'filter[office_category_id]': this.mapOfficeCategoryId[this.officeType],
        'pagination[type]': 'cursor',
        search: this.filter.search,
        'page[limit]': 5,
        'fields[simple-offices]': 'name,code,office,office_id',
        include: 'office.area,office.current-warehouse',
        'fields[offices]':
          'address,phone,multiplied_deposit_balance,deposit_balance,current-warehouse,area',
      });

      this.isLoading = false;
    },
  },
};
</script>
