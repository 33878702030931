import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';

export const orderCreateMixin = {
  data() {
    return {
      order: {
        id: null,
        isCustomAddress: false,
        attributes: {
          code: null,
          date: dayjs().format('YYYY-MM-DD'),
          order_shipment: 'pickup',
          notes: null,
          is_valid_for_payment: false,
        },
        relationships: {
          buyerType: {
            id: null,
            attributes: {
              code: null,
            },
          },
          destinationOffice: {
            id: null,
            attributes: {
              code: null,
              name: null,
              balance: null,
              restock_balance: null,
              deposit_balance: null,
              address: null,
              city: null,
              phone: null,
            },
            relationships: {
              area: {
                id: null,
                attributes: {
                  code: null,
                },
              },
            },
          },
          destinationWarehouse: {
            id: null,
            attributes: {
              code: null,
            },
          },
          originOffice: {
            id: null,
            attributes: {
              code: null,
            },
          },
          originWarehouse: {
            id: null,
            attributes: {
              code: null,
              name: null,
            },
            relationships: {
              area: {
                id: null,
                attributes: {
                  code: null,
                },
              },
            },
          },
          area: {
            id: null,
            attributes: {
              code: null,
            },
          },
          destinationAddress: {
            attributes: {
              address: null,
              province: {
                id: null,
                name: null,
              },
              city: {
                id: null,
                name: null,
                area: null,
              },
              district: {
                id: null,
                name: null,
              },
              village: {
                id: null,
                name: null,
              },
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getSetups: 'setups/getSetups',
      me: 'auth/getUser',
    }),
    isDraft() {
      return !!this.order.id && !this.order.attributes.is_valid_for_payment;
    },
    canAddProduct() {
      return (
        this.order.relationships.originOffice.id &&
        this.order.relationships.destinationOffice.id &&
        this.order.relationships.originWarehouse.id
      );
    },
    minOrderSpend() {
      return Number(
        this.getSetups.data.length
          ? this.getSetups.data[0].attributes.setup_value
          : 0
      );
    },
    selectedArea() {
      // kode gudang origin
      const originAreaCode =
        this.order.relationships.originWarehouse.relationships.area.attributes
          .code;
      // kode area alamat pengiriman atau kode area destinasi
      const destinationAreaCode = this.order.isCustomAddress
        ? this.order.relationships.destinationAddress.attributes.city.area
        : this.order.relationships.destinationOffice.relationships.area
            .attributes.code;

      if (destinationAreaCode === undefined || destinationAreaCode === null) {
        return originAreaCode;
      }

      // area wilayah harga jika metode transaksi diambil gunakan area kp jika dikirim dibandingkan yang tertinggi
      if (this.order.attributes.order_shipment === 'pickup') {
        return originAreaCode;
      }

      return Math.max(originAreaCode, destinationAreaCode);
    },
    rawDestinationOfficeCode() {
      return this.order.relationships.destinationOffice.attributes.code?.split(
        ' '
      )[0];
    },
  },
  methods: {
    ...mapActions({
      fetchSetups: 'setups/fetchSetups',
      fetchBanksByOffice: 'banks/fetchBanksByOffice',
      fetchWarehouseById: 'warehouses/fetchWarehouseById',
      fetchAreas: 'areas/fetchAreas',
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchBuyerTypes: 'buyer_types/fetchBuyerTypes',
      fetchOrder: 'orders/fetchOrder',
      updateOrder: 'orders/updateOrder',
      validate: 'orders/validate',
      fetchPaymentMethodTypesByOffice:
        'payment_method_types/fetchPaymentMethodTypesByOffice',
    }),
    // actions
    async validateOrder(data = {}) {
      const order = await this.validate({ id: this.order.id, data });

      return order ? order.data.data : null;
    },
    // setup
    setupAttributes(attributes = {}) {
      this.order.attributes.order_shipment =
        attributes.order_shipment ?? 'pickup';
    },
    async setupPaymentMethodTypes({ officeId } = {}) {
      this.fetchPaymentMethodTypesByOffice({
        officeId,
        params: { 'filter[is_reserved]': false },
      });
    },
    async setupMinOrder(key) {
      await this.fetchSetups({
        'filter[setup_key]': key,
      });
    },
    async setupOfficeBanks({ officeId } = {}) {
      await this.fetchBanksByOffice({
        office_id: officeId,
      });
    },
    async setupOriginWarehouse({ warehouseId } = {}) {
      const warehouse = await this.fetchWarehouseById({
        id: warehouseId,
      });

      if (warehouse) {
        this.order.relationships.originWarehouse.id = warehouse.data.data.id;
        this.order.relationships.originWarehouse.attributes.code =
          warehouse.data.data.attributes.code;
        this.order.relationships.originWarehouse.attributes.name =
          warehouse.data.data.attributes.name;
        this.order.relationships.originWarehouse.relationships.area.attributes.code =
          warehouse.data.data.attributes.area_code;

        await this.setupArea();
      }
    },
    async setupArea() {
      const areas = await this.fetchAreas({
        keyword: this.selectedArea,
      });

      if (areas) {
        this.order.relationships.area.id = areas.data.data[0].id;
        this.order.relationships.area.attributes.code =
          areas.data.data[0].attributes.code;
      }
    },
    async setupOriginOffice({ officeId }) {
      const office = await this.fetchOfficeById({
        office_id: officeId,
      });

      if (office) {
        this.order.relationships.originOffice.id = office.data.data.id;
        this.order.relationships.originOffice.attributes.code =
          office.data.data.attributes.code;
      }
    },
    async setupBuyerType(code) {
      const buyerTypes = await this.fetchBuyerTypes({
        keyword: code,
      });

      if (buyerTypes) {
        this.order.relationships.buyerType.id = buyerTypes.data.data[0].id;
        this.order.relationships.buyerType.attributes.code =
          buyerTypes.data.data[0].attributes.code;
      }
    },
    async setupOrder(id) {
      const order = await this.fetchOrder({
        id,
        include:
          'buyer-type,destination-office,destination-warehouse,origin-office,origin-warehouse,area,province,city,district,village,order-details,payments',
      });

      if (order) {
        const orderType =
          order.data.data.attributes.order_type == 'purchase'
            ? 'origin-office'
            : 'destination-office';
        const destinationOffice = this.getSingleIncluded(
          order.data,
          order.data.data.relationships[orderType].data.id
        );
        const destinationWarehouse = this.getSingleIncluded(
          order.data,
          order.data.data.relationships[orderType].data.id
        );
        this.order.id = id;
        this.order.isCustomAddress = false;

        this.order.attributes.code = order.data.data.attributes.code;

        this.order.attributes.date = dayjs(
          order.data.data.attributes.createdAt
        ).format('YYYY-MM-DD');
        this.order.attributes.order_shipment =
          order.data.data.attributes.order_shipment;
        this.order.attributes.notes = order.data.data.attributes.notes;
        this.order.attributes.is_valid_for_payment =
          order.data.data.attributes.is_valid_for_payment;

        const buyerType = this.getSingleIncluded(
          order.data,
          order.data.data.relationships['buyer-type'].data.id
        );

        this.order.relationships.buyerType.id = buyerType.id;
        this.order.relationships.buyerType.attributes.code =
          buyerType.attributes.code;

        this.order.relationships.destinationOffice.id = destinationOffice.id;
        this.order.relationships.destinationOffice.attributes =
          destinationOffice.attributes;

        this.order.relationships.destinationWarehouse.id =
          destinationWarehouse.id;
        this.order.relationships.destinationWarehouse.attributes.code =
          destinationWarehouse.attributes.code;

        const originOffice = this.getSingleIncluded(
          order.data,
          order.data.data.relationships['origin-office'].data.id
        );

        (this.order.relationships.originOffice.id = originOffice.id),
          (this.order.relationships.originOffice.attributes.code =
            originOffice.attributes.code);

        const originWarehouse = this.getSingleIncluded(
          order.data,
          order.data.data.relationships['origin-warehouse'].data.id
        );

        (this.order.relationships.originWarehouse.id = originWarehouse.id),
          (this.order.relationships.originWarehouse.attributes.code =
            originWarehouse.attributes.code);
        this.order.relationships.originWarehouse.attributes.name =
          originWarehouse.attributes.name;

        const area = this.getSingleIncluded(
          order.data,
          order.data.data.relationships['area'].data.id
        );

        this.order.relationships.area.id = area.id;
        this.order.relationships.area.attributes.code = area.attributes.code;

        const province = this.getSingleIncluded(
          order.data,
          order.data.data.relationships['province'].data?.id
        );
        const city = this.getSingleIncluded(
          order.data,
          order.data.data.relationships['city'].data?.id
        );
        const district = this.getSingleIncluded(
          order.data,
          order.data.data.relationships['district'].data?.id
        );
        const village = this.getSingleIncluded(
          order.data,
          order.data.data.relationships['village'].data?.id
        );

        if (province) {
          this.order.relationships.destinationAddress.province.id = province.id;
          this.order.relationships.destinationAddress.province.id.name =
            province.attributes.name;
        }

        if (city) {
          this.order.relationships.destinationAddress.city.id = city.id;
          this.order.relationships.destinationAddress.city.id.name =
            city.attributes.name;
          this.order.relationships.destinationAddress.city.id.area =
            city.attributes.area;
        }

        if (district) {
          this.order.relationships.destinationAddress.district.id = district.id;
          this.order.relationships.destinationAddress.district.id.name =
            district.attributes.name;
        }

        if (village) {
          this.order.relationships.destinationAddress.village.id = village.id;
          this.order.relationships.destinationAddress.village.id.name =
            village.attributes.name;
        }
      }
    },
  },
};
