<template>
  <div>
    <BaseModal
      :showing="visible"
      :showClose="visible"
      :backgroundClose="true"
      size="max-w-6xl"
      v-on:close="$emit('close')"
    >
      <div class="flex justify-between px-4 py-5 sm:px-6">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Daftar Gudang
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Data Gudang berdasarkan gudang yang dipilih
          </p>
        </div>
        <div>
          <img src="@/assets/images/logo.png" alt="" class="w-52" />
        </div>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div class="flex justify-end">
          <base-search
            placeholder="Cari Kode"
            v-model="filter.search"
            v-on:input="handleSearch"
          />
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getWarehouses.meta.page.total"
              :perPage="getWarehouses.meta.page.perPage"
              :currentPage="getWarehouses.meta.page.currentPage"
              @pagechanged="handleChangePage"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Gudang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Gudang
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody>
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getWarehouses.data"
                    :key="data.id"
                    :class="[
                      'cursor-pointer',
                      dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                    ]"
                    @click="handleChangeWarehouse(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ data.attributes.code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.name }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <button
          @click="$emit('close')"
          type="button"
          class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
        >
          Tutup
        </button>
      </div>
    </BaseModal>
    <loading v-if="isLoading" text="Memuat Kantor" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/components/base/BaseModal';
import Datatable from '@/components/base/Datatable';
import { debounce } from 'debounce';
import BaseSearch from '@/components/base/Search.vue';

export default {
  components: { BaseModal, Datatable, BaseSearch },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    params: Object,
    warehouseType: {
      type: String,
      required: true,
    },
  },
  emits: ['change'],
  data() {
    return {
      isLoading: false,
      filter: {
        search: null,
      },
      page: {
        number: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      getWarehouses: 'warehouses/getWarehouses',
    }),
  },
  watch: {
    visible(value) {
      if (value) {
        this.loadWarehouses({ resetPage: true, resetFilter: true });
      }
    },
  },
  methods: {
    ...mapActions({
      fetchWarehouses: 'warehouses/fetchWarehouses',
    }),
    handleSearch: debounce(function () {
      this.loadWarehouses({ resetPage: true });
    }, 300),
    handleChangePage(page) {
      this.page.number = page;

      this.loadWarehouses();
    },
    handleChangeWarehouse(warehouse) {
      this.$emit('change', warehouse);
    },
    async loadWarehouses({ resetPage, resetFilter } = {}) {
      this.isLoading = true;

      if (resetPage) {
        this.page.number = 1;
      }

      if (resetFilter) {
        this.filter.search = null;
      }

      await this.fetchWarehouses({
        ...this.params,
        warehouseType: this.warehouseType,
        search: this.filter.search,
        pageNumber: this.page.number,
        pageSize: 5,
      });

      this.isLoading = false;
    },
  },
};
</script>
