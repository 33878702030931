<template>
  <div class="flex justify-between border-b border-gray-200 pb-5">
    <h3 class="text-lg font-bold leading-6 text-gray-900">Faktur</h3>
    <span
      v-if="isDraft"
      class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
    >
      Draft
    </span>
  </div>
</template>
<script>
export default {
  name: 'PenjualanHeader',
  props: {
    isDraft: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
