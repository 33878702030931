<template>
    <div>
        <div class="flex justify-end">
          <router-link
            :to="cancelRedirect"
            class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
          >
            Batal
          </router-link>
          <button
            v-if="
              totalPrice <= minOrderSpend ||
              (mustMatchPaymentAmount ? totalPrice !== totalPaymentAmount : false)
            "
            disabled
            class="ml-3 inline-flex rounded-md border border-gray-300 bg-gray-300 py-2 px-4 text-sm font-medium text-white shadow-sm"
          >
            Proses
          </button>
          <button
            v-else
            @click="$emit('process')"
            class="focus:outline-none router-link-active ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
          >
            Proses
          </button>
        </div>
      </div>
</template>

<script>
export default {
    props: {
        totalPrice: Number,
        minOrderSpend: Number,
        totalPaymentAmount: Number,
        cancelRedirect: {
          type: String,
          required: true
        },
        mustMatchPaymentAmount: {
          type: Boolean,
          default: false
        }
    },
    emits: ['process']
}
</script>